

<template>
  <!-- 轮播图 -->
  <van-swipe @change="onChange" :autoplay="4000">
    <van-swipe-item v-for="item in list" :key="item">
      <img :src="item" alt="" />
    </van-swipe-item>
    <template #indicator>
      <div class="custom-indicator">{{ current + 1 }}/{{ list.length }}</div>
    </template>
  </van-swipe>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return { current: 0 };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    onChange(index) {
      this.current = index;
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.van-swipe {
  ::v-deep .van-swipe-item {
    img {
      width: 100%;
      height: 375px;
      object-fit: cover;
      display: block; //消除间隙
    }
  }
}

.custom-indicator {
  position: absolute;
  right: 15px;
  bottom: 20px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(30, 30, 30, 0.3);
  color: #fff;
  border-radius: 10px;
}
</style>
