<template>
  <div class="goods-box" ref="scrollbox">
    <div class="menu-box-h flex_sb" v-if="showNavBar">
      <div class="menus">
        <van-tabs v-model="menu" @change="menusChange">
          <van-tab title="商品" :name="1" />
          <van-tab title="详情" :name="2" />
          <!-- <van-tab title="评价" :name="3" /> -->
        </van-tabs>
      </div>
      <div class="collection">
        <!-- 收藏 -->
        <img @click="collectionHandle" src="~@/assets/img/home/d2.png" alt="" />
        <!-- 分享 -->
        <img @click="shareHandle" src="~@/assets/img/home/share_b.png" alt="" />
      </div>
    </div>
    <div class="content">
      <!-- banner -->
      <div class="banner-box">
        <swiper :list="goodsInfo.picUrls" />
        <div class="menu-box flex_sb">
          <div class="menus"></div>
          <div class="collection">
            <div class="img">
              <!--  已收藏，-->
              <img
                @click="collectionHandle"
                src="~@/assets/img/home/d.png"
                alt=""
              />
              <!-- 未收藏 -->
              <!-- <img src="~@/assets/img/home/d3.png" alt="" /> -->
            </div>
          </div>
          <!--  分享-->
          <div class="collection">
            <div class="img" @click="shareHandle">
              <img src="~@/assets/img/home/share.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 限时抢购需要展示的部分 -->
      <!-- <div class="limite flex_sb">
        <div class="price">
          <div class="now"><span>￥</span>499.00</div>
          <del class="old">￥899.00</del>
        </div>
        <div class="time-count">
          <p class="hint">距结束还剩</p>
          <div>
            <van-count-down :time="time">
              <template #default="timeData">
                <span class="block">{{ timeData.hours }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds }}</span>
              </template>
            </van-count-down>
          </div>
        </div>
      </div> -->

      <div class="goods-info" ref="goods">
        <div class="price-box flex_sb">
          <div class="price"><span>￥</span>{{ goodsInfo.priceDown }}</div>
          <p class="hint">已售{{ goodsInfo.saleNum }}</p>
        </div>
        <p class="goods-name line2">
          {{ goodsInfo.name }}
        </p>
        <p class="desc">{{ goodsInfo.sellPoint }}</p>
        <!-- 服务保证 -->
        <div class="services-box">
          <div class="services-item" v-for="item in result" :key="item.id">
            <img src="~@/assets/img/check.png" alt="" />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="handle-box">
        <!-- <van-cell title="领券" is-link @click="receiveClick">
          <div class="copon">满500减100</div>
          <div class="copon">满1000减200</div>
        </van-cell> -->
        <van-cell title="已选" is-link :value="checkInfo" @click="checkCat" />
        <van-cell title="配送" value="商家配送" />
      </div>
      <!-- 商品评价 -->
      <!-- <div class="evaluate-box" ref="evaluate">
        <div class="page-title flex_sb" @click="jumpEvaluate">
          <p class="title">商品评价(23)</p>
          <p class="more">查看全部</p>
          <i class="next"></i>
        </div>
        <div class="buyer flex_sb">
          <div class="picture">
            <img src="~@/assets/img/profile/head.png" />
          </div>
          <div class="info">
            <div class="top flex_sb">
              <p class="name">唯有杜康</p>
              <p class="time">2021-06-07 9:27</p>
            </div>
            <div class="bot">
              <van-rate disabled v-model="rate" size="12" />
            </div>
          </div>
        </div>
        <div class="evaluate-content">
          <p>
            包装设计精美，大气沉稳上档次，口感也不错，物流也很快，一次
            不错的购物体验！
          </p>
          <div class="evaluate-img">
            <img src="~@/assets/img/b.jpg" alt="" />
            <img src="~@/assets/img/b.jpg" alt="" />
            <img src="~@/assets/img/b.jpg" alt="" />
          </div>
        </div>
        <p class="buyer-content">规格：【迎雙喜】500ML单瓶</p>
      </div> -->
      <div class="details-img">
        <div class="page-title">
          <p class="title">商品详情</p>
        </div>
        <div class="details" ref="details">
          <div v-html="goodsInfo.description"></div>
          <!-- <img src="~@/assets/img/d.jpg" alt="" /> -->
        </div>
      </div>
    </div>
    <div class="buy-menu">
      <van-goods-action v-fitIphoneX="{ type: 'padding', pxNum: -5 }">
        <van-goods-action-icon
          :icon="require('@/assets/img/profile/services.png')"
          text="客服"
          @click="jumpServiceHandle"
        />
        <van-goods-action-icon
          :icon="require('@/assets/img/cart.png')"
          text="购物车"
          @click="jumpCartHandle"
        />
        <van-goods-action-button
          @click="addCart"
          class="join-cart"
          text="加入购物车"
        />
        <van-goods-action-button @click="buy" class="buy" text="立即购买" />
      </van-goods-action>
    </div>
    <div class="Pop-box">
      <van-popup
        v-model="show"
        position="bottom"
        closeable
        round
        :close-on-click-overlay="false"
        :style="{ height: '80%' }"
      >
        <div class="pop-content">
          <div class="pop-title">优惠券</div>
          <div class="cat-content" :style="{ height: '90%' }">
            <scroller>
              <coupon-list />
            </scroller>
          </div>
        </div>
      </van-popup>
    </div>
    <div class="skus">
      <van-sku
        v-model="showSku"
        :sku="sku"
        :goods="goods"
        :goods-id="goodsId"
        price-tag="新品"
        buy-text="确认"
        :quota="0"
        :quota-used="0"
        :show-add-cart-btn="false"
        :hide-stock="sku.hide_stock"
        :initial-sku="sku.list[0]"
        @buy-clicked="onBuyClicked"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { jumpApp, closePreventDefault } from "@/mixin/share.js";
import swiper from "@/components/common/goods/swiper.vue";
import couponList from "@/components/common/coponList.vue";
import navbarbox from "@/components/common/navbarbox.vue";
export default {
  components: { swiper, navbarbox },
  props: {},
  data() {
    return {
      menu: "", //菜单
      show: false,
      showNavBar: false,
      popkey: "",
      popsetting: {
        coupon: { popheight: 85, title: "优惠券" },
        join: { popheight: 70, title: "加入购物车" },
        buy: { popheight: 70, title: "立即购买" },
        check: { popheight: 70, title: "选择规格" },
      },
      result: [], //商家配置如7天退换货，品质保证等
      rate: 3, //评分
      time: 30 * 60 * 60 * 1000, //倒计时
      checkInfo: "",
      showSku: false,
      sku: {
        tree: [],
        list: [],
        price: "0.00", // 默认价格（单位元）
        stock_num: 227, // 商品总库存
        collection_id: 2261, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
        none_sku: false, // 是否无规格商品
        messages: [
          {
            // 商品留言
            datetime: "0", // 留言类型为 time 时，是否含日期。'1' 表示包含
            multiple: "0", // 留言类型为 text 时，是否多行文本。'1' 表示多行
            name: "留言", // 留言名称
            type: "text", // 留言类型，可选: id_no（身份证）, text, tel, date, time, email
            required: "0", // 是否必填 '1' 表示必填
            placeholder: "", // 可选值，占位文本
            extraDesc: "", // 可选值，附加描述文案
          },
        ],
        hide_stock: false, // 是否隐藏剩余库存
      },
      goods: {
        // 默认商品 sku 缩略图
        picture: "",
      },
      goodsInfo: {},
    };
  },
  computed: {
    id() {
      // return "1273190776913063938";
      return this.$route.params.id;
    },
    goodsId() {
      return this.id;
    },
  },
  watch: {},
  methods: {
    ...mapActions({ loadGoods: "goods/loadGoods" }),
    async load() {
      const { goodsInfo, skuInfo, listEnsureInfo } = await this.loadGoods(
        this.id
      );
      // 保存商品信息
      this.goodsInfo = goodsInfo;
      this.goods.picture = this.goodsInfo.picUrls[0];
      // console.log(this.goodsInfo);
      this.result = listEnsureInfo;
      // 过滤规格列表
      for (let i = 0; i < skuInfo.length; i++) {
        const item = skuInfo[i];
        const treeItem = { k: item.value, k_s: "s" + (i + 1) };
        const treeV = [];
        for (const treeVItem of item.leaf) {
          const o = { id: treeVItem.id, name: treeVItem.value };
          treeV.push(o);
        }
        treeItem.v = treeV;
        this.sku.tree.push(treeItem);
      }
      // 为sku的list赋值
      for (const item of goodsInfo.skus) {
        const listItem = {
          id: item.id,
          price: parseFloat(item.salesPrice) * 100,
          stock_num: item.stock,
        };
        for (let i = 0; i < item.specs.length; i++) {
          const element = item.specs[i];
          listItem["s" + (i + 1)] = element.specValueId;
        }

        this.sku.list.push(listItem);
      }

      // 为默认的选择规格赋值
      let checkInfo = "";
      console.log(goodsInfo.skus[0]);
      this.sku.price = goodsInfo.skus[0].salesPrice;
      for (const c of goodsInfo.skus[0].specs) {
        checkInfo += c.specValueName + " ";
      }
      this.checkInfo = checkInfo;
    },
    // 收藏
    collectionHandle(e) {
      closePreventDefault(e);
      var str=''
      let url = window.location.href;
            let result = {};
            if(url.indexOf('?')>-1){
                 str = url.split('interviewUrl=')[1];
            }
      jumpApp("goodsDetail", { id: this.id ,url:str});
    },
    // 分享
    shareHandle(e) {
      closePreventDefault(e);
      var str=''
      let url = window.location.href;
            let result = {};
            if(url.indexOf('?')>-1){
                 str = url.split('interviewUrl=')[1];
            }
      jumpApp("goodsDetail", { id: this.id ,url:str});
    },
    // 前往客服
    jumpServiceHandle(e) {
      closePreventDefault(e);
      var str=''
      let url = window.location.href;
            let result = {};
            if(url.indexOf('?')>-1){
                 str = url.split('interviewUrl=')[1];
            }
      jumpApp("goodsDetail", { id: this.id ,url:str});
      console.log("jumpServiceHandle");
    },
    // 前往购物车
    jumpCartHandle(e) {
      closePreventDefault(e);
      var str=''
      let url = window.location.href;
            let result = {};
            if(url.indexOf('?')>-1){
                 str = url.split('interviewUrl=')[1];
            }
      jumpApp("goodsDetail", { id: this.id ,url:str});
    },
    // sku确认按钮
    onBuyClicked(value, $event) {
      // console.log(value);
      this.showSku = false;
      // 为默认的选择规格赋值
      const item = this.goodsInfo.skus.find(
        (item) => item.id === value.selectedSkuComb.id
      );
      if (item) {
        let checkInfo = "";
        for (const c of item.specs) {
          checkInfo += c.specValueName + " ";
        }
        this.checkInfo = checkInfo;
      }
      var str=''
      let url = window.location.href;
            let result = {};
            if(url.indexOf('?')>-1){
                 str = url.split('interviewUrl=')[1];
            }
      jumpApp("goodsDetail", { id: this.id ,url:str});
    },

    onClickLeft() {
      console.log("back");
    },
    receiveClick() {
      this.show = true;
    },
    // 选择规格
    checkCat() {
      this.showSku = true;
    },
    // 加入购物车按钮
    addCart() {
      this.showSku = true;
    },
    // 购买按钮
    buy() {
      this.showSku = true;
    },

    menusChange(name, title) {
      switch (name) {
        case 1: //顶部
          this.$refs.scrollbox.scrollTo({
            top: 130,
            behavior: "smooth",
          });
          break;
        case 2: //评价
          const topDetailsV = this.$refs.details.offsetTop - 150;
          this.$refs.scrollbox.scrollTo({
            top: topDetailsV,
            behavior: "smooth",
          });
          break;
        case 3: //详情
          const topEvaluateV = this.$refs.evaluate.offsetTop - 150;
          console.log(topEvaluateV);
          this.$refs.scrollbox.scrollTo({
            top: topEvaluateV,
            behavior: "smooth",
          });
          break;
      }
    },

    // 查看全部评价
    jumpEvaluate() {
      this.$router.push(`/evaluatePage/${1}`);
    },
    handleScroll(e) {
      let scrollTop = this.$refs.scrollbox.scrollTop;

      this.showNavBar = scrollTop > 76;
    },
  },
  created() {
    this.load();
  },
  mounted() {
    this.$refs.scrollbox.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
