<template>
  <div class="copon-box">
    <div class="receive-title copon-title">
      可领取优惠券<span class="tag">领取后可用于购物车商品</span>
    </div>
    <div class="receive-list">
      <div class="copon" v-for="item in 2" :key="item">
        <div class="left">
          <div class="content">100<span>元</span></div>
          <div class="hint">满999可用</div>
        </div>
        <div class="right">
          <div class="top">
            <p class="tags">全品类优惠券</p>
            仅可购买指定端午节活动商品使用仅可购买指定端午节活动商品使用
          </div>
          <div class="bottom flex_sb">
            <p class="time text_hide">2021.06.01-2021.06.30</p>
            <p class="btns">
              <van-button @click.stop="receive" class="receive-btn"
                >点击领取</van-button
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="complete-title copon-title">
      已领取优惠券 <span class="tag">以下是您账户里已领取的优惠券</span>
    </div>
    <div class="receive-list">
      <div class="copon" v-for="item in 2" :key="item">
        <div class="left">
          <div class="content">100<span>元</span></div>
          <div class="hint">满999可用</div>
        </div>
        <div class="right">
          <div class="top">
            <p class="tags">全品类优惠券</p>
            仅可购买指定端午节活动商品使用仅可购买指定端午节活动商品使用
          </div>
          <div class="bottom flex_sb">
            <p class="time text_hide">2021.06.01-2021.06.30</p>
            <p class="btns">
              <van-button @click.stop="receive" class="complete-btn"
                >去凑单</van-button
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
    receive() {},
  },
  components: {},
};
</script>

<style scoped lang="scss">
.copon-box {
  padding-bottom: 30px;
  .copon-title {
    font-size: 18px;
    color: $main;
    box-sizing: border-box;
    white-space: nowrap;
    padding: 12px;
    .tag {
      display: inline-block;
      padding-left: 10px;
      font-size: 12px;
      color: #999;
    }
  }
  .copon {
    height: 100px;
    padding: 5px;
    background-image: url("~@/assets/img/cart/copon.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .left {
      text-align: center;
      width: 30%;
      white-space: nowrap;
      .content {
        font-size: 24px;
        color: #fff;
        padding: 10px 0;
        span {
          font-size: 14px;
        }
      }
      .hint {
        font-size: 12px;
        color: #f85b5b;
        background: #fff;
        display: inline-block;
        padding: 4px 12px;
      }
    }
    .right {
      flex: 1;
      box-sizing: border-box;
      padding: 7px;
      position: relative;
      overflow: hidden;
      .top {
        font-size: 15px;
        color: $main;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        .tags {
          display: inline-block;
          color: #fff;
          font-size: 12px;
          background: #f85353;
          border-radius: 10px;
          padding: 4px;
        }
      }
      .bottom {
        position: absolute;
        bottom: 7px;
        left: 7px;
        right: 10px;

        .time {
          flex: 1;
          font-size: 13px;
          color: $main;
        }
        .btns {
          .receive-btn {
            height: 24px;
            background: #f85353;
            border-radius: 12px;
            border-color: #f85353;
            font-size: 12px;
            color: #fff;
            padding: 0 12px;
          }
          .complete-btn {
            color: #f85353;
            border-color: #f85353;
            border-radius: 12px;
            height: 24px;
            font-size: 12px;
            padding: 0 12px;
          }
        }
      }
    }
  }
}
</style>
